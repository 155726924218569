import React, { createContext, useState, useEffect } from 'react';

// @ts-ignore
import { dictionaryList } from '../languages/languages';

export const AppContext: any = createContext(null);

export const ContextProvider = (props: { children: any }): JSX.Element => {
    // initial language: german
    const [language, setLanguage] = useState('de');
    const [currentLanguageDict, setCurrentLanguageDict] = useState(dictionaryList[language]);

    // --- language setup ---
    // change current language based on language state
    let tmpLanguageDict: Record<string, unknown>;
    language === 'en' ? (tmpLanguageDict = dictionaryList[language]) : (tmpLanguageDict = dictionaryList[language]);

    // update state only when tmpLanguageDict changes
    useEffect(() => {
        setCurrentLanguageDict(tmpLanguageDict);
    }, [tmpLanguageDict]);

    return (
        <AppContext.Provider
            value={{
                value1: [language, setLanguage],
                value2: [currentLanguageDict, setCurrentLanguageDict],
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};
